<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="Выберите" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2> Протокол прямой трансляции "UniLive" </h2>
		<p> Протокол прямого вещания UniLive - это Pix Technology PTE. LTD.(далее именуемый "Наша сторона") и Ваше (вы являетесь физическим лицом, юридическим лицом или другой
			организацией) электронное соглашение, имеющее юридическую силу и регулирующее права и обязательства сторон, далее
			именуемое "Настоящее Соглашение". Ваша галочка « Я согласен » или нажатие кнопки « Я прочитал и выполнил это
			соглашение» означает, что вы внимательно прочитали, полностью поняли и полностью приняли все условия настоящего
			Соглашения без каких - либо оговорок.
		</p>
		<h3>Основные правила прямых трансляций Uni Live</h3>
		<p>
			Контент трансляций
		</p>
		<p>
			1. Незаконная и вредоносная информация  
			Запрещено распространять контент, связанный с политически чувствительными темами, сепаратизмом, религиозным экстремизмом и т. д.  
			Категорически запрещается демонстрировать насилие, кровь, ужасы, азартные игры, наркотики или любые другие незаконные действия.  
			Продвижение суеверий (например, гадания, предсказания) должно быть ограничено и не допускать чрезмерной пропаганды.  
		</p>
		<p>
			2. Порнографический и непристойный контент  
			Запрещены обнажённые изображения, сексуальные намёки, непристойные выступления или любой другой контент порнографического характера.  
			Одежда не должна быть чрезмерно откровенной (например, открытая грудь, ягодицы, прозрачные наряды и т. д.), иначе трансляция может быть прервана.  
			Запрещены провокационные выражения или поведение, связанные с сексуальными темами.  
		</p>
		<p>
			3. Нарушение прав других людей  
			Запрещено публиковать личную информацию третьих лиц без их разрешения (например, имя, адрес).  
			Кибербуллинг, злонамеренные атаки или клевета строго запрещены.  
		</p>
		<p>
			4. Мошенничество и обман  
			Запрещено распространять слухи, ложную рекламу или непроверенную информацию (например, преувеличенные заявления о пользе медицинских товаров).  
			Мошенничество, финансовые пирамиды и побуждение пользователей к личным сделкам запрещены.  
		</p>
		<p>
			5. Контент, связанный с несовершеннолетними  
			Несовершеннолетние должны находиться под строгой защитой. Детям запрещено участвовать в прямых трансляциях.  
			Запрещено демонстрировать контент, который может нанести вред физическому или психическому здоровью несовершеннолетних.  
		</p>

		<h3>Основные правила загрузки видео на Uni Live</h3>
		<p>
			Руководство по контенту (стандарты сообщества)
		</p>
		<p>
			Запрещён насильственный контент: нельзя загружать видео с демонстрацией насилия, причинения вреда, жестокого обращения или терроризма, включая реальные и инсценированные сцены.  
		</p>
		<p>
			Запрещён порнографический и откровенный контент: обнажённые сцены, сексуальные намёки, порнография или любой другой контент для взрослых строго запрещены.  
		</p>

		<p>
			Запрещены выражения ненависти: нельзя публиковать контент, направленный на дискриминацию, нападки или разжигание ненависти по признаку расы, религии, пола, сексуальной ориентации, национальности и т. д.  
		</p>
		<p>
			Запрещены запугивание и домогательства: нельзя загружать контент, унижающий, угрожающий или преследующий других людей, включая ненадлежащее поведение в отношении детей.  
		</p>
		<p>
			Запрещены опасные действия: нельзя демонстрировать видео с опасными челленджами, розыгрышами или незаконной деятельностью (например, употребление наркотиков, использование оружия), которые могут привести к травмам.  
		</p>

		<h3> Первое общее правило </h3>
		<p> 1 Платформа UniLive - это платформа Pix Technology PTE. LTD. и ее аффилированные лица, включая, но не
			ограничиваясь этим, Pix Technology PTE. LTD. Собственная платформа прямого вещания. В соответствии с нашими регистрационными требованиями и правилами вы
			подаете заявку на то, чтобы стать нашим поставщиком услуг прямой трансляции (или « поставщиком прямой трансляции»)
			на нашей законно эксплуатируемой платформе UniLive (далее именуемой « Платформой»), предоставляя пользователям
			нашей платформы услуги прямой трансляции видеоконтента в режиме онлайн, и вы должны рассматриваться как период
			действия соглашения в течение периода предоставления услуг нашей платформой. Мы не рассматриваем заранее
			загруженный, с вашим участием, редактирование и производство видеоконтента, описанного выше, и не берем на себя
			инициативу по редактированию, сортировке, изменению и обработке таких видео.
		</p>
		<p>
			До подписания настоящего Соглашения Вы полностью осведомлены о наших правилах и требованиях, а также имеете право,
			возможность и квалификацию для выполнения обязанностей и обязательств, согласованных в настоящем Соглашении.
			Настоящее Соглашение представляет собой действительное, обязательное и подлежащее принудительному исполнению
			юридическое обязательство для Вас, и Вы четко знаете все положения и определения, содержащиеся в настоящем
			Соглашении, и не сомневаетесь в этом.
		</p>
		<p> 3. Вы обязуетесь и заявляете, что при предоставлении услуг нам соблюдаются соответствующие положения
			законодательства страны пребывания, и не должны совершать других действий, противоречащих законодательству Китая и
			страны пребывания, во имя выполнения настоящего Соглашения. </p>
		<p> 4. Ваши трудовые, трудовые и трудовые отношения с нами не имеют никакого уровня трудового законодательства, и мы
			не обязаны выплачивать вам пособия и льготы по социальному страхованию. </p>
		<p> 5. Вы не можете осуществлять какие - либо действия, связанные с прямыми трансляциями, на сторонних конкурентных
			платформах без нашего предварительного письменного согласия. </p>
		<p> 6 Прежде чем зарегистрироваться в качестве ведущего канала UniLive или осуществлять прямую трансляцию на
			платформе UniLive, вы подтвердили, что не подписали и не имеют действующего соглашения об эксклюзивной прямой
			трансляции с какой - либо сторонней платформой. </p>
		<p> 7 В течение периода сотрудничества между двумя сторонами ваши соответствующие права на прямую трансляцию,
			загрузку, публикацию или передачу контента применяются к условиям Соглашения об обслуживании пользователей
			UniLive. </p>
		<h3> Статья 2 Наши права и обязательства </h3>
		<p> 1. Мы имеем право устанавливать систему эксплуатации Платформы и правила для операторов прямого вещания, а также
			в рамках настоящего Соглашения, управлять и контролировать Вашу сторону и вносить изменения или изменения в
			соответствующие правила в соответствии с эксплуатационными условиями. </p>
		<p>
			2. Мы имеем право инспектировать и судить Вас с целью установления (отмены) вознаграждения или наказания для Вас.
			Конкретные исследовательские проекты и стандарты устанавливаются нами отдельно без дополнительного согласия Вас.
			Если мы просим заключить с вами отдельное официальное соглашение о сотрудничестве в прямом эфире, вы не можете
			отказать или каким - либо иным образом замаскировать отказ в подписании [в противном случае вы должны выплатить
			нам единовременную неустойку [50 000] (с большой буквы: Wu Wan) юаней].
		</p>
		<p>
			Мы имеем право предлагать предложения и предложения по улучшению вашей службы прямого вещания, вы должны внести
			соответствующие исправления в течение [3] рабочих дней после получения наших предложений и замечаний, в противном
			случае мы имеем право ограничить, закрыть, возместить или прекратить использование вами UniLive Live Live
			Division, и мы должны вычесть стоимость услуг, которые должны быть оплачены вам (если таковые имеются), что может
			привести к определенным потерям для вас, которые вы несете сами, и мы не несем никакой ответственности.
		</p>
		<p> 4 Мы принимаем окончательное решение по вопросам, связанным с прямой трансляцией, которую вы проводите. </p>
		<p> 5, мы имеем право использовать ваше имя (включая, но не ограничиваясь этим, ваше настоящее имя, псевдоним,
			сетевое имя, предыдущее имя и любые буквенные символы, представляющие вашу личность), портреты (включая, но не
			ограничиваясь, реальные портреты и мультипликационные портреты) для всех видов рекламы на нашей платформе. </p>
		<p> 6, мы несем ответственность за предоставление поддержки технических услуг Платформы, а также за расчет расходов
			на услуги Платформы (если таковые имеются). </p>
		<h3> Статья 3 Ваши права и обязанности </h3>
		<p>
			Вы должны использовать подлинную идентификационную информацию и персональные данные и не должны регистрироваться и
			сертифицироваться на основе ложной и мошеннической информации о личности резидента, информации о регистрации
			предприятия, информации о коде организации. В случае каких - либо изменений в Ваших персональных данных они должны
			быть своевременно обновлены. Мы запрещаем трансляцию несовершеннолетних в прямом эфире, и если законный опекун
			желает, чтобы несовершеннолетний имел возможность предоставлять услуги в прямом и прямом эфире, предусмотренные
			настоящим Соглашением, он должен определить в качестве опекуна, подходит ли содержание таких услуг для
			несовершеннолетнего, и нести ответственность за все последствия этого. Вы обязуетесь не наносить ущерба законным
			интересам какой - либо третьей стороны в результате осуществления настоящего Соглашения, и вы принимаете и
			выполняете настоящее Соглашение без нарушения каких - либо юридических документов, имеющих для вас обязательную
			силу, и не возлагаете на нас ответственность перед какой - либо третьей стороной.
		</p>
		<p> 2. Вы сами возьмете на себя ответственность за сеть, необходимую для проведения прямых трансляций, оборудования,
			поддерживающего видео и голос, и гарантируете, что изображения в прямом эфире чисты, качество голоса ясное и
			стабильное. </p>
		<p> 3. Вы обязуетесь, в соответствии с установленной Платформой процедурой подачи заявок в прямом эфире, представить
			необходимые Платформе заявки и добровольно внести соответствующую гарантию. </p>
		<p> 4 Вы обязуетесь, что комната прямой трансляции должна использоваться для прямой трансляции и не должна
			использоваться для каких - либо других мероприятий, не связанных с прямой трансляцией. <b> При выполнении Вами
				доставки товаров в прямом эфире Вы и Ваш поставщик должны соблюдать положения китайского законодательства и
				выполнять соответствующие положения обнародованного нами Соглашения о коммерческом обслуживании. </b>
		</p>
		<p>
			5. Вся информация / информация / замечания / контент, размещенные Вами в прямом эфире в соответствии с настоящим
			Соглашением и / или на Платформе, не должны содержать содержания, противоречащего соответствующим законам,
			правилам и положениям Китайской Народной Республики, включая, но не ограничиваясь этим, материалы, которые ставят
			под угрозу национальную безопасность, порнографию, ложную, незаконную клевету, клевету (включая коммерческую
			клевету), незаконное запугивание или незаконное преследование, посягательство на интеллектуальную собственность,
			личные права, коммерческую тайну или другие законные права и интересы других лиц, а также контент или ссылки на
			них, которые нарушают общественный порядок.
		</p>
		<p>
			Вы обязуетесь активно поддерживать имидж нашей и нашей платформы, Вы не будете совершать действий, которые наносят
			ущерб имиджу или интересам нашей и / или нашей платформы. В течение периода действия настоящего Соглашения и после
			его прекращения Вы не будете подразумевать или публиковать по каким - либо каналам (включая, но не ограничиваясь
			этим, веб - сайты, блоги, Weibo, WeChat, чаты QQ, встречи игроков и т. Д.) заявления, которые наносят ущерб нашей
			и / или нашей платформе.
		</p>
		<p>
			Без нашего письменного согласия вы ни в коем случае не должны ссылаться на соответствующую информацию о
			конкурентной платформе третьей стороны в какой - либо форме (включая, но не ограничиваясь этим, тексты, устные
			трансляции, видеоплагины и т.д.
		</p>
		<h3> Статья 4 Расходы на обслуживание и расчеты </h3>
		<p>
			1. При условии, что вы предоставляете пользователям Платформы услуги прямой трансляции, пользователь может
			совершить для вас потребление виртуальных подарков, вы можете подать заявку на расчет соответствующих доходов
			(если таковые имеются) в соответствии с нашими требованиями и правилами расчетов, мы взимаем определенную долю
			платы за технические услуги и выплачиваем вам соответствующие налоги. Что касается потребления виртуальных
			подарков, полученных необычными способами, мы имеем право на независимое суждение и обработку.
		</p>
		<p> 2. Плата за услуги, которые вы получаете, должна уплачиваться в соответствии с соответствующими национальными
			законами и правилами, мы оплачиваем полученные вами услуги на банковский счет, который вы заполняете в центре
			пользователя, и вы можете запросить соответствующую информацию в отчете о доходах после входа на нашу платформу
			(расчетные данные являются налоговыми данными). </p>
		<p>
			Если вы являетесь физическим лицом, зарегистрированным на нашей платформе и сертифицированным лицом, мы имеем
			право оплатить услуги, полученные вами, на ваш личный банковский счет, заполненный в центре пользователя; Если вы
			являетесь юридическим лицом или другой организацией, зарегистрированной на нашей платформе и сертифицированной
			Агентством, мы имеем право оплатить расходы на услуги, полученные вами, на счет учреждения, который вы заполнили
			на странице аккредитации Агентства, но вы должны предоставить нам эквивалентную сумму действительного специального
			счета - фактуры по НДС (номер счета - фактуры - плата за услуги прямой трансляции) в течение пяти рабочих дней до
			оплаты, и задержка платежа, вызванная задержкой в представлении счета - фактуры, не является нарушением
			обязательств. Мы оплачиваем услуги по счету, который вы заполняете, и считаем, что мы выполнили свои платежные
			обязательства по настоящему Соглашению. Если Вы являетесь юридическим лицом или другой организацией, расходы,
			понесенные Вашим сотрудником или Вашим ведущим в связи с администрированием и эксплуатацией учетной записи и ее
			прямой трансляции, оплачиваются Вами и Вашим сотрудником или Вашим ведущим. В случае возникновения спора,
			судебного разбирательства или возмещения убытков, понесенных Вами в результате урегулирования таких расходов
			(включая, но не ограничиваясь этим, авансы, выплаченные Вами в счет заработной платы Вашим сотрудникам или их
			ведущим), мы имеем право на первый вычет из суммы причитающихся Вам расходов на обслуживание, и мы имеем право
			взыскать с Вас недостающую часть.
		</p>
		<p> 4. Вы гарантируете правильное, правдивое и эффективное заполнение информации о счете и несете исключительную
			ответственность за любые платежные ошибки, допущенные нами из - за информации о счете. В то же время, если вам
			необходимо изменить информацию о счете, вы должны своевременно уведомить нас в письменной форме, и информация о
			новом счете вступает в силу в следующем расчетном месяце после подачи вашей заявки и утверждения нашей стороной.
		</p>
		<h3> Статья 5 Конфиденциальность </h3>
		<p> 1. Вы должны строго соблюдать нашу систему конфиденциальности и обязуетесь сохранять нашу коммерческую тайну на
			неопределенный срок. Вы возмещаете нам любой прямой или косвенный ущерб репутации, репутации или экономике,
			понесенный нами в результате использования или раскрытия нашей коммерческой тайны и информации в нарушение вашего
			соглашения.
			Если юаня неустойки недостаточно, чтобы компенсировать наши потери, вы также должны возместить наши потери. </p>
		<p>
			2. Коммерческая тайна означает всю информацию, связанную с нашим бизнесом, которая может принести нам финансовую
			выгоду, практическую и негласную, включая (но не ограничиваясь этим): техническую информацию, деловую информацию и
			информацию и документы, относящиеся к нашему административному управлению (включая содержание настоящего
			Соглашения и связанных с ним соглашений), сумму и порядок расчетов за услуги, которые вы получаете от нас,
			стандарты, способ присвоения прав, способ авторизации, имя клиента, список других операторов прямой трансляции,
			контактные данные, стоимость услуг, список сотрудников и т.п. А.
		</p>
		<p> 3. Вы должны строго соблюдать настоящее Соглашение и не должны разглашать нашу коммерческую тайну без нашего
			письменного разрешения или согласия: </p>
		<ul>
			<li> (1) Распространение, разглашение любым способом третьим лицам или неуточненной общественности; </li>
			<li> (2) Использовать нашу коммерческую тайну не для целей настоящего Соглашения. </li>
		</ul>
		<p> 4. После прекращения действия настоящего Соглашения Вы возвращаете нам всю нашу коммерческую тайну или под нашим
			контролем уничтожаете все документы, в которых содержится наша коммерческая тайна. </p>
		<p> 5 Положения настоящей статьи остаются в силе после прекращения действия настоящего Соглашения. </p>
		<h3> Изменение, расторжение, прекращение действия Соглашения Статья 6 </h3>
		<p>
			Мы имеем право при необходимости изменять, приостанавливать или прекращать действие настоящего Соглашения и
			уведомлять об этом на соответствующих страницах. Измененное Соглашение заменяет собой первоначальное Соглашение,
			как только оно будет опубликовано на соответствующих страницах. После изменения условий настоящего Соглашения,
			если вы продолжаете предоставлять услуги прямой трансляции, такие как прямые трансляции, пользователям нашей
			Платформы, вы будете считаться осведомленным и принятым вами измененным соглашением. Если вы не согласны с любыми
			изменениями, внесенными нами в настоящее Соглашение, вы должны немедленно уведомить нас в письменном виде и
			прекратить любые прямые трансляции на нашей платформе.
		</p>
		<p> 2. Стороны могут прекратить действие настоящего Соглашения путем консенсуса в отношении его расторжения. </p>
		<p> 3, у вас есть одно из следующих обстоятельств, мы можем расторгнуть настоящее соглашение немедленно, без
			предварительного уведомления: </p>
		<ul>
			<li> (1) Если мы обнаружим, что вы нарушили заявления и обязательства, сделанные в отношении настоящего
				Соглашения; </li>
			<li> (2) если ваши действия прямо или косвенно наносят значительный ущерб нашим интересам; </li>
			<li> (3) Нарушение национальных законов и правил; </li>
			<li> (4) нарушение других обязательств по настоящему Соглашению; </li>
			<li> (5) выполнение настоящего Соглашения в негативном, бездействии и других формах, не соответствующих нашим
				требованиям (даже если это не является нарушением), которые не были исправлены в течение 10 дней после нашего
				уведомления; </li>
			<li> (6) В связи с возникновением исключительных обстоятельств мы считаем вас непригодными для выполнения услуг по
				настоящему Соглашению, которые не устранены в течение 10 дней после нашего уведомления; </li>
			<li> (7) В связи с корректировкой нашего бизнеса, больше не проводить услуги прямой трансляции бизнеса. </li>
		</ul>
		<p> 4. В связи с расторжением или прекращением Соглашения, вызванного пунктами 1 и 2 статьи 6 настоящего Соглашения,
			мы рассчитаем с вами расходы на обслуживание в соответствии со статьей 4 настоящего Соглашения и политикой нашей
			Платформы в режиме реального времени. </p>
		<p> 5 В связи с расторжением или прекращением действия Соглашения в соответствии с пунктом 3 статьи 6 настоящего
			Соглашения мы имеем право вычесть из Вашего счета все расходы на обслуживание, которые не были оплачены, и
			потребовать от Вас взять на себя ответственность за нарушение Соглашения. </p>
		<h3> Статья 7 Ответственность за нарушение </h3>
		<p> 1. Любые иски, претензии и / или последствия ущерба нашей репутации, которые могут быть предъявлены нам в
			результате ложных заявлений или нарушения обязательств, взятых на себя Вами в соответствии с этими заявлениями,
			будут нести все прямые и косвенные расходы, убытки и убытки, понесенные нами в результате этого, включая расходы,
			понесенные нами в связи с судебным разбирательством, и расходы на оплату услуг адвоката. </p>
		<p>
			Если иное не согласовано в настоящем Соглашении, если вы нарушаете какие - либо положения настоящего Соглашения
			или если предоставляемые вами услуги не соответствуют нашим требованиям, мы имеем право в одностороннем порядке
			принять соответствующие ограничения или санкции, включая, но не ограничиваясь этим, ограничение, закрытие,
			восстановление или прекращение использования вами прямых трансляций UniLive, ограничение или прекращение
			использования отдельных услуг (например, видеотрансляций) и принятие решения о возобновлении использования в
			соответствии с фактическими обстоятельствами, за вычетом неурегулированных расходов на обслуживание в вашем
			аккаунте.
		</p>
	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/LiveAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/LiveAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/LiveAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/LiveAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/LiveAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/LiveAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/LiveAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/LiveAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/LiveAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/LiveAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/LiveAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/LiveAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/LiveAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/LiveAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>